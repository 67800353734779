@import "../../styles/main";

.toggle-switch {
	border: 2px solid $green;
	padding: 3px;

	.radio-button {
		width: 50%;

		.button {
			margin: 0;
			width: 100%;
		}
	}
}

@import "../../styles/main";

.link-list-box {
	color: $primary-white;
	background-color: $lighter-grey;
	display: block;
	font-size: 16px;
	font-weight: normal;
	padding: 18px;
	text-align: center;

	@include media($bp-2) {
		display: flex;
	}

	&__title {
		margin-bottom: 6px;

		@include media($bp-2) {
			display: flex;
			margin: 0;
		}
	}

	&__list {
		list-style: none;

		@include media($bp-2) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__link {
		color: #74c5dd;
		margin-top: 6px;
		text-decoration: underline;

		@include media($bp-2) {
			display: flex;
			margin-left: 12px;
			margin-top: 0;
		}
	}
}

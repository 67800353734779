.split-when-large {
	float: left;
	width: 100%;

	@include media($bp-2) {
		margin-left: 1%;
		margin-right: 1%;
		width: 48%;
	}

	@include media($bp-max) {
		width: 49%;

		&--left {
			margin-left: 0;
			margin-right: 1%;
		}

		&--right {
			margin-left: 1%;
			margin-right: 0;
		}
	}
}

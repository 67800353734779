@import "../../styles/main";

.banner {
	@include outer-container;

	clear: both;
	margin-bottom: 16px;
	position: relative;

	&--no-margin {
		margin-bottom: 0;
	}

	&__image {
		width: 100%;
	}

	&__edge-gradient-overlay {
		display: none;
		position: absolute;
		top: 0;

		@include media($bp-1) {
			@include background(
				linear-gradient(
					to right,
					$primary-grey 0%,
					rgba(0, 0, 0, 0) 5%,
					rgba(0, 0, 0, 0) 95%,
					$primary-grey 100%
				)
			);
			@include filter(blur(2px));

			display: block;
			height: 103%;
			margin: -0.5%;
			width: 101%;
		}
	}

	&__bottom-overlay {
		background-color: $lighter-grey;
		clear: both;
		color: $primary-white;
		margin: 0;
		text-align: center;
		width: 100%;

		&__title {
			font-family: $emphasis-font;
			font-size: rem(22);
			line-height: rem30;
			padding: rem(15);
			position: relative;
			text-align: center;
			z-index: 2;

			@include media($bp-1) {
				font-size: rem(34);
				line-height: rem(37);
			}
		}

		&__subtitle {
			font-family: $emphasis-font;
			font-size: rem(18);
			font-weight: normal;
			padding: rem(12);
			position: relative;
			text-align: center;
			z-index: 2;

			@include media($bp-1) {
				font-size: rem(22);
				line-height: rem(30);
			}
		}

		.button--info {
			position: relative;
			z-index: 2;
		}
	}
}

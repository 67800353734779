@import "../../styles/main";
// This should probably be moved to some kind of container element style
.icon-tile-list {
	background-color: $darker-grey;
}

.icon-tiles-wrapper {
	@include outer-container;
}

.icon-tile-container {
	@include media($bp-1) {
		@include span-columns(1 of 3);

		.chevron {
			display: none;
		}
	}
}

.icon-tile {
	color: $primary-white;
	display: block;
	font-weight: bold;
	height: rem(100);
	margin-bottom: 2px;
	padding: rem(10);
	transition: background-color 0.3s;
	@include media($bp-1) {
		height: auto;
	}

	&:hover {
		background-color: $primary-grey;

		.icon .before,
		.icon:before {
			color: lighten($primary-yellow, 25%);
		}
	}

	&__icon {
		color: $primary-yellow;
		display: block;
		height: 70px;
		margin: 13px auto;
		position: absolute;
		text-align: center;
		width: 70px;
		@include media($bp-1) {
			position: static;
		}

		&:before {
			float: left;
			transition: color 0.3s;
			@include media($bp-1) {
				float: none;
			}
		}
	}

	&__text {
		@include center-vertically;
		color: $primary-white;
		line-height: 1;
		padding: 0 rem(80);

		&__title {
			color: $primary-yellow;
			font-size: 2em;
		}

		&__subtitle {
			font-size: 1.3em;
			margin-top: rem(7);
		}
		@include media($bp-1) {
			padding: 0 rem(20);
			text-align: center;
			transform: none;
		}
	}
}

@import "../../styles/main";
@import "../Button/Button";

.form-container {
	background-color: darken($orange, 5%);
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: 90%;

	@include media($bp-1) {
		width: 100%;
	}

	&__inner {
		padding: 7%;

		&--slide {
			@include transition(transform 0.5s);
			position: absolute;
			width: 100%;

			&:first-child {
				display: block;
				position: relative;
				width: auto;
			}

			&.shift-left {
				@include transform(translatex(-100%));
			}
		}

		.success-message {
			font-size: 20px;
			font-weight: bold;
			text-align: center;
		}
		.error-message {
			background-color: $primary-yellow;
			color: $darker-grey;
			font-size: 20px;
			font-weight: bold;
			padding: 10px;
		}

		&__label {
			display: block;
			font-size: 25px;
		}

		&__radio {
			opacity: 0;
			position: absolute;

			&:checked ~ .form-radio-label {
				background-color: $green;

				.show-when-selected {
					display: block;
				}

				.hide-when-selected {
					display: none;
				}
			}
		}

		&__radio-label {
			@extend .button--emphasis;
			font-size: rem(27);
			padding: rem(12);

			.show-when-selected {
				display: none;
				width: 100%;
			}

			input {
				color: $primary-grey;
				padding: rem(5);
				text-align: center;
			}
		}

		&__radio-list {
			@include unstyled_list;
			@include horizontal-list();

			margin: 0 auto;
			overflow: hidden;
			margin-bottom: 10px;
		}

		&__textbox {
			background-color: $primary-white;
			color: $primary-grey;
			display: block;
			font-size: 20px;
			height: 50px;
			padding: 10px 15px;
			width: 100%;

			@include media($bp-3) {
				font-size: 30px;
			}
		}

		&__textarea {
			background-color: $primary-white;
			color: $primary-grey;
			display: block;
			font-size: 18px;
			min-height: 200px;
			padding: 10px 15px;
			width: 100%;
		}

		&__title {
			font-size: 40px;
			font-weight: bold;
			text-align: center;
		}

		&__step {
			background-color: #222;
			margin: 10px;
		}

		&__step-heading {
			color: $primary-yellow;
			font-size: 32px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		&__row {
			padding: 7px 0;
		}
	}

	&__info {
		font-size: 20px;
		font-weight: bold;
		left: 100%;
		padding: 7%;
		position: absolute;
		text-align: center;
		top: 0;

		&--slide {
			@include transition(transform 0.5s);
			position: absolute;
			width: 100%;

			&:first-child {
				display: block;
				position: relative;
				width: auto;
			}

			&.shift-left {
				@include transform(translatex(-100%));
			}
		}
	}

	.privacy-notice {
		font-size: 16px;

		a {
			text-decoration: underline;
		}
	}
}

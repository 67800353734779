@import "../../styles/main";

.donate-page {
	.form-container {
		background-color: $lighter-grey;
		overflow: hidden;
		padding: 3% 7%;

		.form-row {
			position: relative;
			text-align: left;
			@include media($bp-2) {
				@include span-columns(1 of 2, 4%);
			}

			.pf {
				position: absolute;
				margin-top: 4px;
				right: 5px;
			}
		}
	}

	.button--info {
		margin: rem(15) auto;
	}

	.thankyou-message {
		padding: rem(50) 0;
	}
}

// Restricts the width of an element so it doesn't look absurd on huge screens
.restricted-container {
	@include outer-container;

	// Adds some padding to the sides on smaller screens
	&--padded {
		margin: 0 15px;

		@include media($bp-max) {
			margin: 0 auto;
		}
	}
}

.text-container.text-document {
	font-size: 18px;
	margin: 20px;

	h3 {
		margin-top: 15px;
	}

	ul,
	ol {
		padding-left: 40px;
	}

	li {
		margin-top: 8px;
	}
}

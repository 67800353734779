$primary-grey: #181818;
$primary-yellow: #f8f400;
$primary-white: #f7f7f7;

$darker-grey: darken($primary-grey, 6%);
$darkerer-grey: darken($primary-grey, 10%);

$lighter-grey: lighten($primary-grey, 6%);
$lighterer-grey: lighten($lighter-grey, 10%);
$lightererer-grey: lighten($lighterer-grey, 20%);

$orange: #ff7e00;
$green: #73bf03;
$red: #e44545;

$primary-bgcolor: $primary-grey;
$primary-textcolor: $primary-white;
$strong-textcolor: $primary-yellow;
$primary-text-link-color: #2c86ff;
$light-text-link-color: #7ab0f9;

// Brand colors
$twitter-brand-color: #4099ff;
$facebook-brand-color: #3b5998;
$instagram-brand-color: #007bb6;
$gofundme-brand-color: #5e7f08;

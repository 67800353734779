@import "../../styles/main";
@import "./social-media-links";

.main-footer {
	clear: both;
	position: relative;
	text-align: center;
	@include outer-container;
	max-width: 950px;

	.top-footer-content {
		overflow: hidden;
	}

	.copyright {
		color: darken($primary-white, 25%);
		font-size: 14px;
		clear: both;
		padding: 4px;
	}

	.footer-links {
		@include unstyled_list;
		text-transform: lowercase;
		margin: 15px 0;
		text-align: center;
		@include media($bp-2) {
			text-align: left;
		}

		li {
			@include media($bp-2) {
				display: inline-block;
				*display: inline;
			}
		}

		&__link {
			padding: 4px 15px;
			transition: color 0.3s;

			&:hover {
				color: $lightererer-grey;
			}

			&--subtle {
				color: darken($primary-white, 30%);
				font-size: rem(12);
			}
		}
	}
}

@import "../../styles/main";

.currency-text-input {
	background-color: $primary-white;
	color: $darkerer-grey;
	cursor: text;
	display: inline-flex;
	padding: 6px;

	&__input {
		flex-grow: 1;
		width: 100%;
	}
}

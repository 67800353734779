.social-media-links {
	margin: rem(10) 0;
	@include media($bp-2) {
		float: left;
	}

	ul {
		@include unstyled_list;
		@include horizontal-list();
		@include media($bp-2) {
			display: inline-block;
		}

		li {
			padding: 0 rem(5);
		}
	}

	&__icon {
		border-radius: rem(50);
		font-size: rem(40);
		height: rem(50);
		line-height: 1.1;
		padding: rem(3) 0;
		transition: background-color 0.3s;
		width: rem(50);

		&:hover {
			background-color: $lighterer-grey;
		}

		&:before {
			transition: color 0.3s;
		}

		.icon {
			height: 40px;
		}
	}
}

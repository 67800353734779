@import "../../styles/main";

.radio-button {
	display: inline-block;

	&__input {
		display: none;

		&:checked + label {
			background-color: $green;
		}
	}
}

@import "../../styles/main";

.content-tile {
	background-color: $primary-white;
	color: $lighter-grey;
	display: block;
	font-weight: normal;
	margin: 0 auto 16px;
	min-height: 250px;
	overflow: hidden;
	position: relative;
	width: 90%;
	@include transition(
		background-color $summary-block-fast-animation-time linear
	);
	@include media($bp-1) {
		width: 100%;
	}
	@include media($bp-2) {
		margin-right: rem(10);
	}
	@include media($bp-4) {
		position: relative;
	}
	@include media($bp-max) {
		margin-left: 0;
		margin-right: 0;
	}

	&--clickable {
		cursor: pointer;

		&:hover {
			background-color: $green;
			@include transition(
				background-color $summary-block-slow-animation-time linear
			);

			.content-tile__image {
				&__arrow {
					border-bottom-color: $green;
					@include transition(
						border-color $summary-block-slow-animation-time linear
					);
					@include media($bp-4) {
						border-bottom-color: rgba(0, 0, 0, 0);
						border-left-color: $green;
					}
				}

				img {
					@include media($bp-4) {
						@include transition(
							transform $summary-block-slow-animation-time linear
						);
						@include transform(translate3d(-50%, -50%, 200px));
					}
				}
			}

			.content-tile__text,
			.full-width {
				.button--info {
					background-color: $primary-white;
					@include transition(
						background-color $summary-block-slow-animation-time linear
					);
				}
			}
		}
	}

	&__image {
		max-height: 325px;
		overflow: hidden;
		width: 100%;
		@include media($bp-4) {
			max-height: rem(400);
		}

		img {
			@include backface-visibility(hidden);
			transform-origin: top left;
			*top: -100px;
			width: 100%;
			z-index: 1;
			@include media($bp-4) {
				left: 50%;
				*left: 0;
			}
		}
	}

	&__text {
		box-sizing: content-box;
		font-size: rem(18);
		padding: rem(20) 4%;
		text-align: center;

		p {
			color: $darker-grey;
			text-align: left;
			margin: 0;
			padding-top: rem(7);
		}

		.button--info {
			color: $darker-grey;
			margin: rem(15) auto 0;
			@include transition(
				background-color $summary-block-fast-animation-time linear
			);
		}

		&--full-width {
			width: 92%;
		}
	}
}

@import "../../styles/main";
@import "./hamburger";
@import "./header-links";
@import "./logo";

.header-links {
	@include transition(max-height 0.2s linear);

	clear: both;
	font-family: $emphasis-font;
	font-size: rem(18);
	font-weight: normal;
	max-height: 0;
	overflow: hidden;
	text-align: center;

	@include media($bp-1) {
		max-height: rem(110);
	}

	@include media($bp-3) {
		clear: none;
		display: inline-block;
		float: right;
		margin-right: rem(135);
	}

	&__link {
		display: table-cell;
		line-height: rem(55);
		padding: 0 rem(10);
		text-align: center;
		text-transform: uppercase;
		transition: background-color 0.15s;
		vertical-align: middle;

		&:hover {
			background-color: $lighter-grey;
		}

		&--subtle {
			color: darken($primary-white, 30%);
			font-size: rem(15);
		}
	}

	/****************************************************************************/
	/****************************************************************************/
	// THIS IS WEIRD LAYOUT STUFF AND SHOULD BE MOVED SOMEWHERE ELSE/REFACTORED //
	/****************************************************************************/
	/****************************************************************************/
	.link-row {
		display: inline-block;
		overflow: hidden; // Forces element to expand to size of contents

		a {
			@include span-columns(1 of 6);

			&:nth-child(-n + 3) {
				@include span-columns(1 of 3);

				@include media($bp-1) {
					width: auto;
				}
			}
			&:nth-child(n + 4):nth-child(-n + 5) {
				@include span-columns(1 of 2);

				@include media($bp-1) {
					width: auto;
				}
			}
			&:nth-child(n + 6):nth-child(-n + 7) {
				@include span-columns(1 of 1);

				@include media($bp-1) {
					width: auto;
				}
			}
		}
	}
	/****************************************************************************/
	/****************************************************************************/
}

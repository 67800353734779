@import "../../styles/main";

.donation-form {
	background-color: $lighter-grey;
	padding: 10px;
	text-align: center;

	&__heading {
		font-size: 40px;
		margin-bottom: 10px;
	}

	&__subheading {
		margin-bottom: 20px;
	}

	&__button {
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}
	}

	&__button-group {
		border-top: 1px solid darken($lighter-grey, 5%);
		margin: 15px 0 7px;
		padding: 15px 0;
		text-align: left;

		.radio-button {
			width: 49%;

			&:nth-child(odd) {
				margin-right: 2%;
			}

			.button--radio {
				width: 100%;
			}
		}

		.currency-text-input {
			width: 49%;
		}
	}
}

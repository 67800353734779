$hamburger-bar-height: 4px;
$hamburger-bar-width: 45px;

.hamburger-menu-button {
	@include span-columns(2);

	text-align: center;
	cursor: pointer;
	height: 50px;
	position: relative;

	span {
		@include transition(background 0.4s);
		background-color: $primary-white;
		border-radius: $hamburger-bar-height;
		display: block;
		height: $hamburger-bar-height;
		margin: 10%;
		position: absolute;
		top: 12px;
		width: 80%;

		&:before,
		&:after {
			@include transition(all 0.4s);

			background-color: $primary-white;
			border-radius: $hamburger-bar-height;
			content: "";
			display: block;
			height: $hamburger-bar-height;
			width: 100%;
		}

		&:before {
			margin-top: -7px - $hamburger-bar-height;
		}

		&:after {
			margin-top: 14px + $hamburger-bar-height;
		}
	}

	&.active {
		span {
			background: none;

			&:before {
				@include transform(rotate(45deg));

				margin-top: 0;
			}
			&:after {
				@include transform(rotate(-45deg));
				margin-top: -$hamburger-bar-height;
			}
		}
	}
}

.hamburger_checkbox {
	display: none;

	&:checked ~ .hamburger-container .hamburger-menu-button {
		span {
			background: none;

			&:before {
				@include transform(rotate(45deg));

				margin-top: 0;
			}
			&:after {
				@include transform(rotate(-45deg));
				margin-top: -$hamburger-bar-height;
			}
		}
	}
}

@mixin active-hamburger() {
}

@import "../../styles/main";

.home-page {
	.social-media-tiles {
		@include media($bp-1) {
			margin: rem(10) auto 0;
			width: 96%;
		}

		@include media($bp-2) {
			float: right;
			margin: 0 1% 0 1%;
			width: 48%;
		}

		@include media($bp-max) {
			@include column-count(2);
			float: right;
			margin-left: 0.1%;
			margin-right: 0;
			width: 49.8%;
		}
	}
}

@import "../../styles/main";

.volunteer-reptile-rescue-landing-page {
	.heading-one {
		font-size: 24px;

		@include media($bp-2) {
			font-size: 36px;
		}
	}
	.heading-two {
		font-size: 18px;
		font-weight: normal;

		@include media($bp-2) {
			font-size: 24px;
		}
	}
	.banner-cta-container {
		background-color: $orange;
		padding: 18px;

		label {
			display: block;
			font-size: 24px;
			margin-bottom: 6px;
		}

		input {
			color: #000;
			display: block;
			margin: 0 auto;
			max-width: 300px;
			padding: 12px;
			position: relative;
			width: 100%;
			z-index: 2;
		}

		.button {
			margin: 12px 0 0;
		}
	}
	article {
		margin: 18px 0 42px;
		text-align: left;
	}
	ul {
		margin: 24px auto 0;
		width: 80%;
	}
	li {
		margin-bottom: 6px;
	}
	.body-text {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 12px;
	}
}

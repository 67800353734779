@import "../../styles/main";

.labelled-content-list {
	@include unstyled-list;
}

.labelled-content-item {
	list-style: none;
	list-style-position: inside;

	&__label {
		@include text-container();
		font-size: rem(30);
		font-weight: bold;
		margin-bottom: rem(5);
		text-align: center;

		@include media($bp-2) {
			border-right: 2px solid $primary-grey;
			float: left;
			margin: 0;
			min-width: rem(200);
			width: 25%;
		}

		&--dark {
			background-color: $darkerer-grey;
			color: $primary-yellow;
		}
	}

	&__content {
		@include text-container();
		margin-bottom: rem(35);
		margin-top: 0;
		padding: rem(20);

		&--dark {
			background-color: $darkerer-grey;
			color: $primary-white;
		}

		.text-container {
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--emphasis {
		margin: rem(15) 0 rem(35);

		@include media($bp-2) {
			display: flex;
			flex-direction: row;
		}

		.labelled-content-item__label {
			color: $primary-white;
			font-size: rem(25);

			@include media($bp-2) {
				border-right: none;
				flex: 1;
				text-align: right;
			}
			@include media($bp-3) {
				font-size: rem(35);
			}
		}

		.labelled-content-item__content {
			color: $primary-white;
			font-size: rem(20);
			margin: 0;
			padding: rem(10) rem(20);

			@include media($bp-2) {
				flex: 3;
			}

			@include media($bp-3) {
				font-size: rem(25);
			}
		}
	}
}

@import "../../styles/main";

// List with items divided by a separator
.divided-list {
	text-align: left;

	li {
		list-style: none;
		list-style-position: inside;
		padding: 9px 0;
		border-bottom: 1px dashed $darker-grey;
		font-weight: bold;
	}
}

// A list of boxes
.block-list {
	@include unstyled-list;

	@include media($bp-1) {
		margin: 0 auto rem(16);
		width: 100%;
	}

	margin-bottom: rem(16);
	overflow: hidden;

	&.row-when-large {
		@include media($bp-2) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			& > * {
				margin-left: 0.5%;
				margin-right: 0.5%;
			}
		}
	}
}

* {
	border: none;
	font-family: inherit;
	//font-size: 100%;
	font-style: inherit;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

html {
	font-family: $primary-font;
	font-size: $primary-fontsize;
}

body {
	background-color: $primary-bgcolor;
	color: $primary-textcolor;
	line-height: 1.4;
}

a {
	color: $primary-white;
	display: inline-block;
	font-weight: bold;
	text-decoration: none;

	&.text-link {
		color: $primary-text-link-color;

		&:hover {
			color: lighten($primary-text-link-color, 10%);
		}
	}

	&.light-text-link {
		color: $light-text-link-color;

		&:hover {
			color: lighten($light-text-link-color, 10%);
		}
	}
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
}

fieldset {
	border: none;
}

.alert{
	background-color: rgb(255, 255, 203);
	border-radius: 12px;
	color:#000;
	margin: 24px 6px;
	padding: 12px 24px;

	a {
		color: rgb(58, 111, 164);
		text-decoration: underline;
		font-weight: normal;
	}
}
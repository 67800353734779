@mixin unstyled-list() {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

@mixin horizontal-list() {
	@include unstyled-list;

	li {
		display: inline-block;
		*display: inline;
		zoom: 1;
	}
}

#body-container {
	font-size: 18px;
	position: relative;
}

.main-header {
	background-color: $darker-grey;
	color: $strong-textcolor;
	font-weight: bold;
	position: relative;
	z-index: 3;

	.header-top {
		@include outer-container;
		height: rem(56);
		position: relative;
		@include media($bp-1) {
			float: left;
		}
	}

	.logo-link {
		@include span-columns(1 of 6);
		display: block;
		height: 100%;
		left: 0;
		min-width: rem(60);
		position: absolute;
		z-index: 1;
		@include media($bp-1) {
			width: 100%;
		}
	}

	.hamburger-menu-button {
		@include span-columns(1 of 6);
		@include user-select(none);
		float: right;
		max-width: 55px;
		@include media($bp-1) {
			display: none;
		}
	}

	#show_menu_checkbox:checked ~ .hamburger-container .header-links {
		max-height: 165px;
	}

	.donate-container {
		max-width: none;
		top: 0;
		width: 100%;
		@include media($bp-1) {
			position: absolute;
		}

		.restricted-container {
			position: relative;
			width: 100%;
		}

		.donate-button {
			border-radius: 0;
			@include media($bp-1) {
				border-radius: rem(10);
				margin-right: 5px;
				margin-top: rem(7);
				position: absolute;
				right: 0;
				text-transform: uppercase;
				top: 0;
				width: auto;
			}
		}
	}
}

@import "../../styles/main";

.heading-box {
	background-color: $darker-grey;
	color: $primary-yellow;
	display: inline-block;
	font-size: 22px;
	padding: 6px 18px;
	text-align: center;

	@include media($bp-2) {
		font-size: 30px;
		line-height: 36px;
	}
}

@import "../../styles/main";

.notfound-page {
	font-family: $emphasis-font;
	text-align: center;

	h2 {
		font-size: 170px;
		line-height: 170px;
		margin: 70px 0 0 0;
		padding: 0;
	}
	h3 {
		font-size: 40px;
	}

	.restricted-container {
		margin-bottom: 120px;
	}
}

@import "../../styles/main";

.rescue-page {
	.sponzor-description {
		h2 {
			color: $primary-yellow;
			text-align: center;
		}
	}
}

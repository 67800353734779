@mixin text-container() {
	background-color: $primary-white;
	color: $darkerer-grey;
	margin: 15px 0;
	overflow: hidden;
	padding: 10px;

	a {
		color: $orange;

		&:hover {
			color: lighten($orange, 10%);
		}
	}
}

.text-container {
	@include text-container();

	&--dark {
		background-color: $darkerer-grey;
		color: $primary-white;
	}
}

//************************************************************************//
// Background property for adding multiple backgrounds using shorthand
// notation.
//************************************************************************//

@mixin background($backgrounds...) {
	$webkit-backgrounds: ();
	$spec-backgrounds: ();

	@each $background in $backgrounds {
		$webkit-background: ();
		$spec-background: ();
		$background-type: type-of($background);

		@if $background-type == string or $background-type == list {
			$background-str: if(
				$background-type == list,
				nth($background, 1),
				$background
			);

			$url-str: str-slice($background-str, 0, 3);
			$gradient-type: str-slice($background-str, 0, 6);

			@if $url-str == "url" {
				$webkit-background: $background;
				$spec-background: $background;
			} @else if $gradient-type == "linear" {
				$gradients: _linear-gradient-parser("#{$background}");
				$webkit-background: map-get($gradients, webkit-image);
				$spec-background: map-get($gradients, spec-image);
			} @else if $gradient-type == "radial" {
				$gradients: _radial-gradient-parser("#{$background}");
				$webkit-background: map-get($gradients, webkit-image);
				$spec-background: map-get($gradients, spec-image);
			} @else {
				$webkit-background: $background;
				$spec-background: $background;
			}
		} @else {
			$webkit-background: $background;
			$spec-background: $background;
		}

		$webkit-backgrounds: append($webkit-backgrounds, $webkit-background, comma);
		$spec-backgrounds: append($spec-backgrounds, $spec-background, comma);
	}

	background: $webkit-backgrounds;
	background: $spec-backgrounds;
}

.logo {
	color: $primary-yellow;
	font-size: 40px;
	text-align: center;

	@include media($bp-1) {
		margin: auto;
		width: auto;
	}

	&__img {
		float: left;
		margin: 0.2em;
		max-width: 1em;
	}

	&__text {
		float: left;
		font-family: Arial, $primary-font;
		line-height: 34px;
		padding: 8px;
		text-align: center;

		@include media($bp-1) {
			text-align: left;
		}
	}
}

@import "../../styles/main";

.button,
button {
	background-color: $lighterer-grey;
	border: none;
	display: block;
	font-size: 22px;
	font-weight: bold;
	outline: none;
	padding: 12px;
	text-align: center;
	width: 100%;

	&--info {
		background-color: $green;
		border-radius: 10px;
		color: $primary-white;
		display: inline-block;
		font-family: $emphasis-font;
		font-weight: normal;
		margin: 0 auto 15px;
		padding: 6px 16px;
		width: auto;

		&:hover {
			background-color: lighten($green, 5%);
		}
	}

	&--radio {
		background-color: $lighterer-grey;
		color: $primary-white;
		cursor: pointer;
		display: inline-block;
		font-family: $emphasis-font;
		font-weight: normal;
		margin: 0 auto 15px;
		padding: 6px 16px;
		width: auto;

		&:hover {
			background-color: lighten($lighterer-grey, 10%);
		}
	}

	&--emphasis {
		background-color: $orange;
		border-radius: 8px;
		color: $primary-white;
		font-family: $emphasis-font;
		font-weight: normal;
		padding: 6px 16px;
		@include appearance(none);

		&:hover {
			background-color: lighten($orange, 5%);
		}
	}
}

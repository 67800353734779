@charset "UTF-8";

@mixin span-columns($span: $columns of $container-columns, $gutter_size: 0%) {
	$columns: nth($span, 1);
	$container-columns: container-span($span);

	$unitless_gutter: $gutter_size/1%;
	$container-columns-with-gutter: $container-columns - 1;

	display: block;
	float: left;
	margin-right: $gutter_size;
	width: (
			(100 - ($unitless_gutter * $container-columns-with-gutter)) *
				($columns / $container-columns)
		) * 1%;

	//width: percentage( ($columns / $container-columns) - ($unitless_gutter * $columns) );

	&:nth-child(#{$container-columns}n) {
		margin-right: 0;
	}
}

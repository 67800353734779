@import "../../styles/main";

.social-media-tile {
	background-color: $primary-white;
	color: $darker-grey;
	display: inline-block;
	font-weight: normal;
	margin: 0 0 12px;
	padding: 8px;
	@include transition(
		background-color $summary-block-fast-animation-time linear
	);
	width: 100%;

	.post-title {
		height: 50px;
		overflow: hidden;

		.user-image {
			float: left;
			height: 40px;
			width: 40px;
		}

		.icon {
			float: right;

			&,
			&:before {
				font-size: 40px;
				line-height: 40px;
			}
		}

		.post-info {
			margin: 0 47px;

			.post-user {
				font-size: 18px;
				font-weight: bold;
				height: 25px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.post-time {
				color: $lightererer-grey;
				font-size: 12px;
				font-weight: normal;
			}
		}
	}

	.post-content {
		font-size: 14px;
		margin-bottom: 8px;

		img {
			display: block;
			margin: 0 auto;
			max-height: 200px;
			max-width: 100%;
		}
	}

	.button--info {
		background-color: $primary-white;
		color: $darker-grey;
		display: block;
		font-size: 16px;
		margin-bottom: 0;
	}

	&--facebook {
		background-color: $facebook-brand-color;

		&:hover {
			background-color: lighten($facebook-brand-color, 5%);
		}

		.post-title {
			&,
			.icon {
				color: $primary-white;
			}

			.post-info {
				.post-time {
					color: darken($primary-white, 10%);
				}
			}
		}

		.post-content {
			background-color: $primary-white;
			color: $darker-grey;
			padding: rem(5);
		}
	}

	&--twitter {
		background-color: $twitter-brand-color;

		&:hover {
			background-color: lighten($twitter-brand-color, 5%);
		}

		.post-title {
			&,
			.icon {
				color: $primary-white;
			}

			.post-info {
				.post-time {
					color: darken($primary-white, 10%);
				}
			}
		}

		.post-content {
			background-color: lighten($twitter-brand-color, 30%);
			color: $darker-grey;
			padding: rem(5);
		}
	}

	&--instagram {
		color: $instagram-brand-color;

		&:hover {
			background-color: darken($primary-white, 5%);
		}

		.post-title {
			&,
			.icon {
				color: $instagram-brand-color;
			}

			.post-info {
				.post-time {
					color: darken($instagram-brand-color, 10%);
				}
			}
		}

		.post-content {
			background-color: darken($primary-white, 10%);
			color: $darker-grey;
			padding: rem(5);
		}

		.button--info {
			background-color: $instagram-brand-color;
			color: $primary-white;
		}
	}

	.gofundme-tile {
		background-color: $gofundme-brand-color;

		&:hover {
			background-color: lighten($gofundme-brand-color, 5%);
		}

		.post-title {
			&,
			.icon {
				color: $primary-white;
			}

			.post-info {
				.post-time {
					color: darken($primary-white, 10%);
				}
			}
		}

		.post-content {
			background-color: lighten($gofundme-brand-color, 30%);
			color: $darker-grey;
			overflow: hidden;
			padding: rem(5);

			.post-donation {
				background-color: $primary-white;
				border-radius: rem(8);
				float: left;
				font-weight: bold;
				margin: rem(5) 5% rem(5) 0;
				padding: rem(12);
				text-align: center;
				width: 45%;

				.post-donation-title {
					color: $lighterer-grey;
					font-size: rem(16);
				}

				.post-donation-amount {
					color: $orange;
					font-size: rem(28);
				}

				.icon {
					background-color: $darker-grey;
					border-radius: rem(36);
					font-size: rem(30);
					height: rem(36);
					line-height: rem(36);
					margin: 0 auto;
					text-align: center;
					width: rem(36);
				}
			}
		}

		.button--info {
			background-color: $orange;
			color: $primary-white;
		}
	}
}

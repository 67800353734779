@import "../../styles/main";

.press-page {
	.leadership-container {
		ul {
			@include unstyled-list;
		}

		li {
			margin: 0 0 rem(40);
		}

		h3,
		h4,
		p {
			margin: 0;
		}

		h3 {
			color: $primary-yellow;
		}

		h4 {
			font-style: italic;
			font-weight: normal;
			margin-bottom: rem(6);
		}
	}
}
